import { Block } from 'baseui/block';
import { HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import React, { useState } from 'react';
import PasteHashInput from '../paste-hash-input/PasteHashInput';
import Password from '../password/Password';
import { Button } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { useSnackbar } from 'baseui/snackbar';
import { Web3Storage } from 'web3.storage';
import { RawContent } from '../../core/models/raw-content.model';
import Tag from '../tag/Tag';
import DisplayFiles from '../display-files/DisplayFiles';
import DisplayCode from '../display-code/DisplayCode';
import CryptoJS from 'crypto-js';

const FetchBin = (): JSX.Element => {
  const [pasteHash, setPasteHash] = useState('');
  const [enablePassword, setEnablePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [language, setLanguage] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [parsedFiles, setParsedFiles] = useState<File[]>([]);
  const [content, setContent] = useState<string>('');

  const { enqueue } = useSnackbar();
  const storageClient = new Web3Storage({
    token: process.env.REACT_APP_WEB3_STORAGE_TOKEN as string
  });

  const createNewPaste = async (): Promise<void> => {
    setLoading(true);
    const res = await storageClient.get(pasteHash);
    if (res === null || !res.ok) {
      enqueue({
        message: 'Content not found'
      });
      setLoading(false);
      return;
    }
    const files = await res.files();
    if (files.length > 1) {
      enqueue({
        message: 'Content is not a paste generated by this APP'
      });
      setLoading(false);
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      let rawContent: RawContent;
      try {
        const result = enablePassword
          ? CryptoJS.AES.decrypt(reader.result as string, password).toString(CryptoJS.enc.Utf8)
          : (reader.result as string);
        rawContent = JSON.parse(result) as RawContent;
      } catch (e) {
        enqueue({
          message: 'The password you entered is incorrect'
        });
        setLoading(false);
        return;
      }
      setIsUpload(rawContent.isFile);
      setLanguage(rawContent.language);
      setTimestamp(rawContent.timestamp);

      const serializedFiles = rawContent.files;
      const files = [];
      if (rawContent.isFile) {
        for (const serializedFile of serializedFiles) {
          // It converts the binary content of serializedFile into an ArrayBuffer and stores it in the fileBuffer variable.
          const fileBuffer = new Uint8Array(serializedFile.content as ArrayBuffer).buffer;
          const file = new File([fileBuffer], serializedFile.name, { type: serializedFile.type });
          files.push(file);
        }
        setParsedFiles(files);
      } else {
        if (typeof serializedFiles[0].content === 'string') {
          setContent(serializedFiles[0].content);
        }
      }
      setLoading(false);
    });
    // read the contents of a file as a binary string
    reader.readAsBinaryString(file);
  };

  return (
    <Block width="100%" className="component">
      <HeadingLevel>
        <ParagraphMedium>Fetch a Paste</ParagraphMedium>
        <PasteHashInput pasteHash={pasteHash} setPasteHash={setPasteHash} />
        <div style={{ marginTop: 16, marginBottom: 16 }} />
        <Password
          enablePassword={enablePassword}
          setEnablePassword={setEnablePassword}
          password={password}
          setPassword={setPassword}
          isFetching={true}
        />
        <div style={{ marginTop: 16, marginBottom: 16 }} />
        <Button size={SIZE.compact} isLoading={loading} onClick={createNewPaste}>
          Fetch Paste
        </Button>
        {(content !== '' || parsedFiles.length > 0) && (
          <div>
            <ParagraphMedium>
              <strong>Content</strong>
            </ParagraphMedium>
            <Tag timestamp={timestamp} />
            {isUpload ? (
              <DisplayFiles parsedFiles={parsedFiles} />
            ) : (
              <DisplayCode language={language} content={content} />
            )}
          </div>
        )}
      </HeadingLevel>
    </Block>
  );
};

export default FetchBin;
